import React, { PureComponent } from 'react';
import uuidv4 from 'uuid/v4';
import SocketClient from '../../utils/socket-client';
import Button from '../../common/Button';
import RTC from '../../utils/rtc-common';
import FastIcon from '../../assets/icon-internet-fast.svg';
import NotFastIcon from '../../assets/icon-internet-not-fast.svg';
import TestIcon from '../../assets/icon-internet-test.svg';
import ProgressBar from './ProgressBar';
import { setStudioId, setToken } from '../../services/api';
import './SpeedTest.css';

export default class SpeedTest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      progress1: 0,
      progress2: 0,
      speedTestSuccess: null,
      bandwidth: 0,
      hide: false,
      message: 'Please wait while we test your connection to the Pando servers.',
      startTimestamp: Date.now(),
    };

    this.MIN_BANDWIDTH = props.minBandwidth || 3;
    this.SPEED_TESTS = props.speedTests || ['2mb', '10mb'];
  }

  _dataChannel = null;
  _speedTestStartTime = null;
  _speedTestIndex = 0;
  _bytesReceived = 0;
  _totalBytesReceived = 0;

  componentDidMount() {
    this._runSpeedTest();
  }

  componentWillUnmount() {
    this._close();
  }

  componentDidUpdate(_, prevState) {
    const { speedTestSuccess } = this.state;
    const { hideContinueButton } = this.props;

    if (hideContinueButton === true && speedTestSuccess !== prevState.speedTestSuccess) {
      if (speedTestSuccess === true) {
        this._onComplete();
      } else if (speedTestSuccess === false) {
        this._close();
        this.props.onFailure();
      }
    }
  }

  _runSpeedTest = async () => {
    try {
      const { eventId, studioId, token, socketServerUrl, isOffWallParticipant } = this.props;

      const uuid = this.props.uuid || uuidv4();
      const roomName = `${uuid}-client-recv`;

      const urlParams = new URLSearchParams(window.location.search);

      const socketServer = urlParams.get('socketServer');
      const _eventId = eventId || urlParams.get('eventId');
      const _studioId = studioId || urlParams.get('studioId');
      const _token = token || urlParams.get('authToken');

      // Needed for show wall
      setStudioId(_studioId);
      if (_token) setToken(_token);

      const _socketServerUrl = socketServerUrl || `https://${socketServer}.letspando.com`;

      if (!SocketClient.socket || SocketClient.socket.disconnected) {
        await SocketClient.setup(_socketServerUrl, uuid, _eventId, _studioId, _token, isOffWallParticipant);
      }
      SocketClient.emit('run-speed-test', { uuid });

      this._rtc = await this._start(roomName);
    } catch (error) {
      console.log(error);
    }
  };

  async _start(roomName) {
    const rtc = new RTC(roomName);
    const pc = await rtc.setup();

    this._setupSpeedTestHooks(pc);

    await new Promise(async (resolve) => {
      const { type, value } = await rtc.getMessage('state');
      if (type === 'state' && value === 'CONFIG_COMPLETED') {
        resolve();
      }
    });

    console.log('Sending READY_FOR_OFFER');
    rtc.sendMessage({
      type: 'state',
      value: 'READY_FOR_OFFER',
    });

    await rtc.startServer();
    return rtc;
  }

  _setupSpeedTestHooks = (pc) => {
    pc.addEventListener('datachannel', this._onDataChannel);
    // NOTE(mroberts): This is a hack so that we can get a callback when the
    // RTCPeerConnection is closed. In the future, we can subscribe to
    // "connectionstatechange" events.
    const { close } = pc;
    pc.close = function () {
      if (this._dataChannel) {
        this._dataChannel.removeEventListener('message', this._onMessage);
        this._dataChannel.removeEventListener('open', this._startSpeedTest);
      }
      return close.apply(this, arguments);
    };
  };

  _onDataChannel = ({ channel }) => {
    if (channel.label !== 'speed-test') {
      return;
    }
    this._speedTestStartTime = Date.now();
    this._dataChannel = channel;
    this._dataChannel.addEventListener('message', this._onMessage);
    this._dataChannel.addEventListener('open', this._startSpeedTest);
  };

  _onMessage = ({ data }) => {
    const bytesExpected = this.SPEED_TESTS[this._speedTestIndex].replace('mb', '') * 1024 * 1024;
    const duration = Date.now() - this._speedTestStartTime;
    const mbps = (8 * this._totalBytesReceived) / (1024 * 1024) / (duration / 1000);
    this.setState({ bandwidth: Math.round(mbps) });
    if (data === 'done') {
      if (this._bytesReceived >= bytesExpected && mbps >= this.MIN_BANDWIDTH) {
        this._bytesReceived = 0;
        if (++this._speedTestIndex < this.SPEED_TESTS.length) {
          this._startSpeedTest();
        } else {
          this.setState({ speedTestSuccess: true, message: 'Your connection is fast enough.' });
        }
      } else {
        this.setState({ speedTestSuccess: false, message: 'Your connection is not fast enough.' });
      }
    } else {
      const bytesExpected = this.SPEED_TESTS[this._speedTestIndex].replace('mb', '') * 1024 * 1024;
      this._bytesReceived += data.length;
      this._totalBytesReceived += data.length;
      if (this._speedTestIndex === 0) {
        this.setState({ progress1: (100 * this._bytesReceived) / bytesExpected });
      } else if (this._speedTestIndex === 1) {
        this.setState({ progress2: (100 * this._bytesReceived) / bytesExpected });
      }
    }
  };

  _startSpeedTest = () => {
    this._dataChannel.send(`run-speed-test-${this.SPEED_TESTS[this._speedTestIndex]}`);
  };

  _close() {
    this.setState({ hide: !this.props.hideContinueButton });
    if (this._rtc && this._rtc.pc) {
      this._rtc.stop();
      this._rtc = null;
    }
  }

  _onComplete = () => {
    this._close();
    this.props.onSuccess(this.state.bandwidth);
  };

  _retry = () => {
    this._close();
    this._speedTestIndex = 0;
    this._bytesReceived = 0;
    this._totalBytesReceived = 0;
    this.setState(
      {
        progress1: 0,
        progress2: 0,
        speedTestSuccess: null,
        bandwidth: 0,
        hide: false,
        message: 'Please wait while we test your connection to the Pando servers.',
      },
      () => {
        this._runSpeedTest();
      },
    );
  };

  render() {
    const { layout, hideContinueButton } = this.props;
    const isHorizontal = layout && layout === 'horizontal';
    const { bandwidth, progress1, progress2, speedTestSuccess, hide, message, startTimestamp } = this.state;
    if (hide) return null;

    let statusColor = '#244B42';
    let statusIndicatorColor = '#36AF6D';
    let bandwidthText = 'FAST';

    if (bandwidth < this.MIN_BANDWIDTH) {
      statusColor = '#461C2B';
      statusIndicatorColor = '#FF062B';
      bandwidthText = 'SLOW';
    } else if (bandwidth < this.MIN_BANDWIDTH * 2) {
      statusColor = '#244B42';
      statusIndicatorColor = '#36AF6D';
      bandwidthText = 'GOOD';
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#1e2a38', padding: 15, color: '#f3f3f3', alignItems: 'center' }}>
        <div className='speed-test-container' style={{ width: isHorizontal ? '100%' : 400 }}>
          <div className='header'>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: 10 }}>
              <div className='name'></div>
              <div className='status' style={{ backgroundColor: statusColor, opacity: Date.now() - startTimestamp > 2000 ? 1 : 0 }}>
                <span className='indicator' style={{ backgroundColor: statusIndicatorColor }} />
                <span>{bandwidthText}</span>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: isHorizontal ? 'row' : 'column' }}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 12,
                width: 376,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: speedTestSuccess === false ? '#461C2B' : '#293746',
                  padding: 15,
                  marginBottom: 15,
                  width: 352,
                }}
              >
                <img
                  src={speedTestSuccess !== null ? (speedTestSuccess === false ? NotFastIcon : FastIcon) : TestIcon}
                  alt='icon'
                  width={60}
                  style={{ marginRight: 15, marginLeft: 10 }}
                />
                <div>{message}</div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                padding: 12,
              }}
            >
              <ProgressBar title='' value={(progress1 + progress2) / 2} />
              {/* <ProgressBar title='Long Test' value={progress2} customStyle={{ marginTop: 5 }} /> */}
            </div>
          </div>
          {speedTestSuccess === false && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                type='secondary'
                text={'Retry'}
                onClick={() => {
                  this._retry();
                }}
              />
            </div>
          )}
          {!hideContinueButton && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: speedTestSuccess !== null ? 1 : 0,
                marginTop: 15,
              }}
            >
              <Button
                text={speedTestSuccess === true ? 'Continue' : 'OK'}
                type={speedTestSuccess === true ? 'primary' : 'secondary'}
                onClick={() => {
                  if (speedTestSuccess) {
                    this._onComplete();
                  } else if (speedTestSuccess === false) {
                    this._close();
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
