import React from 'react';
import swal from '@sweetalert/with-react';
import Storage from './storage';
import Deferred from './deferred';
import CustomMessage from '../common/Messages/CustomMessage';
import WebCamError from '../common/Icons/WebCamError';
import UaParser from 'ua-parser-js';
import { reloadBrowser, isIpad, isFireFox } from './browser-util';

const localStorage = Storage.getLocalStorage();

class MediaDeviceUtil {
  static async getAvailableDevices(retainCache = true) {
    if (!retainCache) {
      delete MediaDeviceUtil.results.availableDevices;
    }
    if (!MediaDeviceUtil.results.availableDevices) {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const availableDevices = devices
        .map((device) => {
          const { deviceId, groupId, kind, label } = device;
          return { deviceId, groupId, kind, label };
        })
        .filter((device) => device.label.toLowerCase().includes('virtual') === false);

      const isLabelAvailable = !!availableDevices.find((device) => device.label.length > 0);
      if (!isLabelAvailable && isFireFox()) {
        await swal({
          buttons: {},
          closeOnClickOutside: false,
          closeOnEsc: false,
          className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
          content: (
            <CustomMessage
              title='Camera or Mic Error'
              body={
                <div style={{ color: '#ddd', fontSize: 13 }}>
                  <p>
                    Please grant access to your camera and microphone and select <b>"Remember this decision"</b>.
                    <br />
                    <br />
                    You might need to click the camera icon in the URL toolbar and reset the permissions.
                  </p>
                  <div>
                    Click{' '}
                    <span className='link' onClick={reloadBrowser}>
                      here
                    </span>{' '}
                    to reload.
                  </div>
                </div>
              }
              renderIcon={() => {
                return isIpad ? null : <WebCamError />;
              }}
            />
          ),
        });
      }
      const kinds = ['audioinput', 'audiooutput', 'videoinput'];
      const devicesMap = {};
      kinds.forEach((kind) => {
        const defaultDevice = availableDevices.find((dev) => dev.kind === kind && dev.deviceId === 'default');
        devicesMap[kind] = availableDevices
          .filter((dev) => dev.kind === kind && dev.deviceId !== 'default')
          .map((dev) => {
            if (defaultDevice && dev.groupId === defaultDevice.groupId) {
              return { ...dev, isDefault: true };
            } else {
              return dev;
            }
          });
      });

      const results = [];
      Object.values(devicesMap).forEach((devices) => {
        results.push(...devices);
      });
      MediaDeviceUtil.results.availableDevices = results;
    }
    return MediaDeviceUtil.results.availableDevices;
  }

  static async getSupportedResolutions(deviceId, retainCache = true) {
    let useSafeResolution;
    try {
      useSafeResolution = localStorage.getItem('useSafeResolution');
    } catch (err) {
      console.error(err);
    }
    // If iOS OR isSafari
    // ['1280x720', '640x480', '320x240']
    MediaDeviceUtil.resolutions = useSafeResolution ? ['640x480'] : MediaDeviceUtil.defaultResolutions;

    if (!retainCache) {
      delete MediaDeviceUtil.supportedResolutions[deviceId];
    }
    if (!MediaDeviceUtil.supportedResolutions[deviceId]) {
      MediaDeviceUtil.supportedResolutions[deviceId] = await new Promise(async (resolve) => {
        const supportedResolutions = [];
        const stream = await MediaDeviceUtil.getUserMedia({ video: { deviceId: { exact: deviceId } } });

        if (!stream) {
          resolve([]);
        }

        const videoTrack = stream.getVideoTracks()[0];

        for (let index = 0; index < MediaDeviceUtil.resolutions.length; index += 1) {
          const r = MediaDeviceUtil.resolutions[index];
          const parts = r.split('x');
          const width = parseInt(parts[0]);
          const height = parseInt(parts[1]);

          try {
            let constraints = {
              width: { exact: width },
              height: { exact: height },
            };
            let isSuccessful = false;
            setTimeout(() => {
              if (!isSuccessful) {
                localStorage.setItem('useSafeResolution', true);
              }
            }, 5000);
            await videoTrack.applyConstraints(constraints);
            isSuccessful = true;
            supportedResolutions.push(r);
          } catch (err) {
            // Ignore
            // console.log(err, width, height);
          }
          if (index === MediaDeviceUtil.resolutions.length - 1) {
            resolve(supportedResolutions);
          }
        }
        stream.getTracks().forEach((track) => track.stop());
      });
    }
    if (MediaDeviceUtil.supportedResolutions[deviceId].length === 0) {
      console.log('Could not find any valid resolution');
      await this._webcamError();
    }
    return MediaDeviceUtil.supportedResolutions[deviceId];
  }

  static async getUserMedia(constraints, closeTracksAfter, showWebcamError = true) {
    try {
      const timerId = setTimeout(() => {
        showWebcamError = true;
        this._webcamError(new Error('Unable to read webcam'));
      }, 15000);
      const stream = await window.navigator.mediaDevices.getUserMedia(constraints);
      if (closeTracksAfter) {
        stream.getTracks().forEach((track) => track.stop());
      }
      clearTimeout(timerId);
      return stream;
    } catch (error) {
      console.log('getUserMediaError', error.code, error.name, error.message);
      const errors = ['OverconstrainedError', 'NotReadableError', 'NotAllowedError'];
      if ((errors.includes(error.name) || error.message === 'Permission denied') && showWebcamError) {
        await this._webcamError(error);
      }
    }
  }

  static async _webcamError(error) {
    let body;
    let isPermissionDenied = error.message === 'Permission denied' || error.name === 'NotAllowedError';
    if (isIpad) {
      body = (
        <ol>
          <div style={{ marginBottom: 10, paddingLeft: 12, fontSize: 13, color: '#fff', marginRight: -10 }}>{error && `Error Details - ${error.message}`}</div>
          <li>Please open Settings on your iPad and scroll down to Safari</li>
          <li>Scroll down to the 'Settings for Websites' section</li>
          <li>Click into Camera & Microphone and select Ask or Allow for both</li>
        </ol>
      );
    } else {
      body = (
        <ol style={{ paddingLeft: 12, fontSize: 13, color: '#fff', marginRight: -10 }}>
          <div style={{ marginBottom: 10 }}>{error && `Error Details - ${error.message}`}</div>
          {!isPermissionDenied && (
            <>
              <li>Please close other applications that may be using your mic or camera.</li>
              <li>Unplug and replug the mic and camera, if possible.</li>
              <li>
                Click{' '}
                <span className='link' onClick={reloadBrowser}>
                  here
                </span>{' '}
                to log back in.
              </li>
            </>
          )}
          {isPermissionDenied && <div>Please allow access to your camera and microphone.</div>}
        </ol>
      );
    }

    await swal({
      buttons: {},
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
      content: (
        <CustomMessage
          title='Camera or Mic Error'
          body={body}
          footerElement={
            <div style={{ fontSize: 14 }}>
              For further assistance please call our Help Desk at the numbers below.
              <br />
              <br />
              <div>1-866-864-2865 (Toll Free - US/Canada)</div>
              <div>1-215-948-9158 (Toll - Intl)</div>
            </div>
          }
          renderIcon={() => {
            return isIpad ? null : <WebCamError />;
          }}
        />
      ),
    });
  }

  static resetPromises() {
    MediaDeviceUtil.videoStreamInitialized = new Deferred();
    MediaDeviceUtil.audioStreamInitialized = new Deferred();
  }

  static getDeviceDetails() {
    const parser = new UaParser();
    const { browser, os, device } = parser.getResult();
    const platform = {
      osName: os.name,
      osVersion: os.version,
      browserName: browser.name,
      browserVersion: browser.version,
      deviceType: device.type,
      deviceVendor: device.vendor,
      deviceModel: device.model,
    };
    if (isIpad) {
      platform.osName = 'iOS';
      platform.osVersion = browser.version.split('.')[0];
    } else if (os.name === 'Mac OS') {
      platform.osVersion = os.version === '10.15.7' ? '10+' : os.version;
    }
    return platform;
  }
}
MediaDeviceUtil.defaultResolutions = isIpad ? ['960x540', '640x480', '320x240'] : ['1280x720', '640x480', '320x240'];
MediaDeviceUtil.supportedResolutions = {};
MediaDeviceUtil.results = {};
MediaDeviceUtil.notesData = null;
MediaDeviceUtil.videoStreamInitialized = new Deferred();
MediaDeviceUtil.audioStreamInitialized = new Deferred();

export default MediaDeviceUtil;
