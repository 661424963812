import React, { PureComponent } from 'react';
import uuidv4 from 'uuid/v4';
import { post, put, setStudioId, setToken } from '../../services/api';
import queryString from 'query-string';
import { push } from 'connected-react-router';
import { store } from '../../store';
import swal from '@sweetalert/with-react';
import Storage from '../../utils/storage';
import {
  OFF_WALL_PARTICIPANT,
  WALL_PRESENTER,
  ONBOARDING,
  IN_SCREENING_QUEUE,
  IN_STUDIO_QUEUE,
  ON_WALL,
  ON_AIR,
  IN_BREAKOUT_ROOM,
  IN_SCREENING,
  OFF_WALL_ROLES,
} from '../../utils/user-util';
import { isIpad } from '../../utils/browser-util';
import offWallTestInfo from '../../.off-wall-test-info.json';
import SocketClient from '../../utils/socket-client';
import MediaDeviceUtil from '../../utils/media-device-util';
import WaitingMessage from '../../common/Messages/Waiting';
import AlreadyConnectedMessage from '../../common/Messages/AlreadyConnectedMessage';
import OffWallParticipantForm from '../../common/OffWallParticipantForm';
import LandingPage from './LandingPage';
import StringUtil from '../../utils/string-util';
import { getNotesUser, verifyNotesData } from '../../utils/notes-util';
import './Login.css';
import '../connect/ConnectScreen.css';

const localStorage = Storage.getLocalStorage();

export default class Login extends PureComponent {
  state = {
    waitingForServers: null,
    beginSystemCheck: false,
    enableAutomatedOnboarding: false,
    alreadyConnected: false,
    platformDetails: null,
    eventParticipantId: null,
  };

  async componentDidMount() {
    const { search } = this.props.location;
    const params = queryString.parse(search);
    const { roomId, db, unid, ShowName, isOffWallTestClient, studioId, isPreview, token: erpToken } = params;

    setStudioId(studioId);
    let erpData;
    if (erpToken) {
      try {
        const { data } = await post('/auth/verifyToken', { token: erpToken });
        erpData = data;
      } catch (e) {
        console.error(e);
        swal({
          title: 'Error',
          text: 'Invalid token',
        });
        return;
      }
    } else if (!isPreview && (!roomId || !db || !unid || !ShowName)) {
      swal({
        title: 'Error',
        text: 'Missing data',
      });
      return;
    }
    if ((isOffWallTestClient && offWallTestInfo) || isPreview) {
      let eventData;
      try {
        const { data } = await post('/event/eventForName', { name: ShowName });
        eventData = data;
      } catch (e) {
        return;
      }
      localStorage.setItem('participantEvent', JSON.stringify(eventData));

      const rndStr = StringUtil.generateRandomString(3);
      const notesInfo = {
        isOffWallTestClient,
        ...offWallTestInfo,
        UNID: `${uuidv4()}`,
        showName: ShowName,
        lastName: offWallTestInfo.lastName + '' + rndStr,
        attendName: offWallTestInfo.attendName + '' + rndStr,
        location: 'Test Location',
        event: eventData,
        studioId,
      };
      store.dispatch(push({ pathname: '/connect', search, state: notesInfo }));
      return;
    }

    try {
      let showName;
      let verifiedData;
      if (!erpData) {
        const { data } = await getNotesUser(db, unid);
        verifiedData = verifyNotesData(data, search);
        showName = ShowName;
      } else {
        verifiedData = erpData;
        showName = erpData.showName;
      }

      let eventData;
      try {
        const { data } = await post('/event/eventForName', { name: showName });
        eventData = data;
      } catch (e) {
        swal({
          title: 'Event Not Found',
          text: `Please double check the event name "${showName}" and try again.`,
        });
        return;
      }
      localStorage.setItem('participantEvent', JSON.stringify(eventData));

      const isBroadcast = eventData.eventType === 'BROADCAST';

      const getUuidResponse = await post('/participant/getUuid', {
        pandoV1UNID: verifiedData.UNID,
        erpId: verifiedData.erpId,
        eventId: eventData._id,
        firstName: verifiedData.firstName,
        lastName: verifiedData.lastName,
        username: verifiedData.username,
        phone: verifiedData.phone,
        location: verifiedData.location,
        role: isBroadcast ? OFF_WALL_PARTICIPANT : verifiedData.role,
        metadata: verifiedData.metadata,
      });
      const {
        uuid,
        role,
        token: authToken,
        eventParticipantId,
        systemCheck,
        screeningCompleted,
        phone,
        location,
        status,
        platform,
        name,
      } = getUuidResponse.data;
      const alreadyConnected = [IN_SCREENING_QUEUE, IN_STUDIO_QUEUE, ON_WALL, ON_AIR, IN_BREAKOUT_ROOM, IN_SCREENING].includes(status);

      setToken(authToken);

      verifiedData.attendName = name;
      verifiedData.role = role || verifiedData.role;
      verifiedData.uuid = uuid;
      verifiedData.event = eventData;
      verifiedData.eventParticipantId = eventParticipantId;
      verifiedData.systemCheck = systemCheck || null;
      verifiedData.phone = phone;
      verifiedData.location = location;
      verifiedData.showName = showName;
      verifiedData.studioId = studioId;

      this._verifiedData = verifiedData;
      MediaDeviceUtil.notesData = verifiedData;

      if (!alreadyConnected) {
        localStorage.setItem('skipAlreadyConnected', 'true');
        localStorage.setItem('uuid', uuid);

        if (isBroadcast) {
          store.dispatch(push({ pathname: '/connect', state: this._verifiedData, search }));
          return;
        }

        if (systemCheck && systemCheck.photoTaken) {
          localStorage.setItem('photoTaken', 'true');
        } else {
          localStorage.removeItem('photoTaken');
          localStorage.removeItem('photoUrl');
        }

        const isOffWall = OFF_WALL_ROLES.includes(role);
        SocketClient.setup(eventData.socketServerUrl, uuid, eventData._id, eventData.studioWall, authToken, isOffWall);
        await new Promise((resolve) => {
          let intervalCount = 0;
          const intervalId = setInterval(() => {
            if (++intervalCount === 10 || SocketClient.socket.connected) {
              clearInterval(intervalId);
              resolve();
            }
          }, 250);
        });
        await this._waitForServers();

        let enableAutomatedOnboarding = false;

        if (eventData.enableAutomatedOnboarding) {
          if (
            role === WALL_PRESENTER &&
            ((systemCheck && systemCheck.isCompatibleBrowser && systemCheck.hasCamMicAccess && systemCheck.bandwidth > 0) || screeningCompleted)
          ) {
            // browser check
          } else if (
            (systemCheck &&
              systemCheck.isCompatibleBrowser &&
              systemCheck.hasCamMicAccess &&
              systemCheck.bandwidth > 0 &&
              systemCheck.canSeeSelf &&
              systemCheck.canSeeAudioSignal &&
              systemCheck.canHearTestSound &&
              systemCheck.canHearSelf &&
              systemCheck.canSeeLoopbackVideo) ||
            screeningCompleted
          ) {
            // browser check
          } else if (!eventData.enableOffWallAutomatedOnboarding && isOffWall) {
            // browser check
          } else {
            enableAutomatedOnboarding = true;
          }
        }
        if (enableAutomatedOnboarding) {
          this.setState({ enableAutomatedOnboarding: true });
        } else if (erpData && !isOffWall) {
          console.log('erpData', erpData);
          await swal({
            buttons: {},
            closeOnClickOutside: false,
            closeOnEsc: false,
            className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
            content: (
              <div className={'horizontal-message-box'} style={{ padding: 0, margin: 0, width: 'auto' }}>
                <div className='content' style={{ marginTop: 0, height: 'auto', background: 'none' }}>
                  <div className='left' style={{ margin: 0 }}>
                    <OffWallParticipantForm
                      onAccept={({ city, state, phoneNumber }) => {
                        if (city && state && phoneNumber) {
                          this._verifiedData.city = city;
                          this._verifiedData.state = state;
                          this._verifiedData.phone = phoneNumber;
                          swal.close();
                          store.dispatch(push({ pathname: '/browser-check', state: this._verifiedData, search }));
                        }
                      }}
                      participantData={{ uuid }}
                      uiVersion='onboarding'
                    />
                  </div>
                </div>
              </div>
            ),
          });
        } else {
          store.dispatch(push({ pathname: '/browser-check', state: this._verifiedData, search }));
        }
      } else {
        this.setState({ alreadyConnected: true, platformDetails: platform, eventParticipantId });
      }
    } catch (error) {
      console.error(error);
    }
  }

  _updateParticipantStatus = async () => {
    try {
      const { eventParticipantId } = this._verifiedData;
      await put(`/eventParticipant/${eventParticipantId}`, {
        status: ONBOARDING,
      });
    } catch (error) {
      console.error(error);
    }
  };

  _beginSystemCheck = () => {
    this.setState({ beginSystemCheck: true }, async () => {
      const { search } = this.props.location;
      await this._updateParticipantStatus();
      store.dispatch(push({ pathname: '/system-check', search, state: this._verifiedData }));
    });
  };

  _waitForServers = () => {
    return new Promise((resolve) => {
      let timerId;
      setTimeout(() => {
        if (this.state.waitingForServers === null) {
          this.setState({ waitingForServers: true });
        }
      }, 1000);

      const _checkServers = () => {
        if (SocketClient.socket && SocketClient.socket.connected) {
          SocketClient.removeListener('servers-are-running');
          SocketClient.addListener('servers-are-running', () => {
            clearTimeout(timerId);
            SocketClient.removeListener('servers-are-running');
            this.setState({ waitingForServers: false });
            resolve();
          });

          SocketClient.emit('are-servers-running', {});
        }
        timerId = setTimeout(_checkServers, 5000);
      };

      _checkServers();
    });
  };

  render() {
    const { waitingForServers, beginSystemCheck, enableAutomatedOnboarding, alreadyConnected, platformDetails, eventParticipantId } = this.state;

    if (alreadyConnected) {
      return (
        <div style={{ display: 'flex', flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <AlreadyConnectedMessage show={null} platform={platformDetails} eventParticipantId={eventParticipantId} />
        </div>
      );
    }

    if (waitingForServers) {
      return (
        <div className='login-message-container'>
          <div className='message-wrapper'>
            <WaitingMessage showName={''} waitingForServers={true} />
          </div>
        </div>
      );
    }

    if (enableAutomatedOnboarding && !beginSystemCheck) {
      return <LandingPage onButtonClick={this._beginSystemCheck} />;
    }

    return null;
  }
}
